import search_icon from '@assets/images/search_icon.png';
import { generateClass } from '@common/component.utils';
import PageSizeCombo from '@components/pagination/PageSizeCombo';

type ButtonProps = {
    children: React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
};

type SearchButtonProps = {
    type?: 'button' | 'submit' | 'reset';
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
};

function Index({ type = 'submit', children, onClick, className }: ButtonProps) {
    const tableClass = generateClass('com_btn', className);
    return (
        <button type={type} onClick={onClick} className={tableClass}>
            {children}
        </button>
    );
}

function SearchButton({ type = 'submit', onClick, className }: SearchButtonProps) {
    const tableClass = generateClass('search_btn', className);

    return (
        <button type={type} onClick={onClick} className={tableClass}>
            <img className="com_img" src={search_icon} alt="" />
        </button>
    );
}

const B = {
    Button: Index,
    Search: SearchButton,
};

export default B;
