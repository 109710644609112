import classNames from 'classnames';

export const generateClass = (baseClass: string, className?: string) => {
    let classes = baseClass.split(' ');

    if (className) {
        const additionalClasses = className.split(' ');

        additionalClasses.forEach((cls) => {
            if (cls.startsWith('remove:')) {
                const classToRemove = cls.slice(7); // 'remove:' 접두사 제거
                classes = classes.filter((c) => c !== classToRemove);
            } else {
                classes.push(cls);
            }
        });
    }

    return classNames(classes);
};
